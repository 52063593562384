import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import Commento from "../components/commento"
import Contact from '../components/contact'
import "../style/static-page.less";
import SectionTitle from "../components/sectiontitle";

export default function StaticPage({ data }) {
  // Probably shouldn't be in the generic base layout but here we are ¯\_(ツ)_/¯
  const readingPage = data.markdownRemark.frontmatter.title === "Where My Mind's Been"
  const aboutPage = data.markdownRemark.frontmatter.description === "About Jon"

  // Leaving this here in case I want to switch a static page to full-width later
  // This was mostly just to make images wider.
  // const fullWidthContent = "col s12"
  const squeezedContent = "col s12 m11 l10"

  return (
    <Layout>
      <Seo
        lang="en"
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
      />
      <div className="container">
        <article className="post">
          {data.markdownRemark.frontmatter.title && 
            <div className="section-title">
              <SectionTitle title={data.markdownRemark.frontmatter.title} />
            </div>
          }
          {/* <div className="head text-primary">
            <h1>{data.markdownRemark.frontmatter.title}</h1>
          </div> */}
          <div className="content row flex">
            {data.markdownRemark.frontmatter.image && (
              <div className="center">
                <div className="img">
                  <GatsbyImage
                    image={data.markdownRemark.frontmatter
                      .image.childImageSharp.gatsbyImageData} alt=""/>
                </div>
              </div>
            )}
            {/* Controls the full-width vs squeezed content */}
            <div
              className={squeezedContent}
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html
              }}
            ></div>
          </div>
          {readingPage && <>
            <hr />
            <p className="small-text section-title" style={{ maxWidth: '420px', margin: 'auto' }}>
              Want to recommend me a book? Tell me about it!
            </p>
            <Commento />
          </>}
          {aboutPage &&
            <Contact header="Get in touch!" />
          }
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      description
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          id
        }
      }
    }
  }
}
`;
